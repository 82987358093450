import { render, staticRenderFns } from "./PartsColors.vue?vue&type=template&id=23a44d58"
import script from "./PartsColors.vue?vue&type=script&lang=js"
export * from "./PartsColors.vue?vue&type=script&lang=js"
import style0 from "./PartsColors.vue?vue&type=style&index=0&id=23a44d58&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PartsColor: require('/codebuild/output/src3218477401/src/client/components/productView/PartsColor.vue').default,MPowerAutocomplete: require('/codebuild/output/src3218477401/src/client/components/MPowerAutocomplete.vue').default})
